<template>
  <div v-if="survey" style="width: 100%"><survey :survey="survey"></survey></div>
</template>

<style lang="scss">
.sv-checkbox--allowhover {
  &:hover {
    .sv-checkbox__svg {
      background-color: transparent !important;
      border: 3px solid rgb(159, 159, 159) !important;
      path {
        display: none;
      }
    }
  }
}
.sv-btn {
  background-color: white !important;
  color: var(--v-primary-base) !important;
  border: 1px solid var(--v-primary-base);
  font-weight: normal;
  border-radius: 8px !important;
  font-size: 18px !important;
  &:hover {
    background-color: var(--v-primary-base) !important;
    color: white !important;
  }
}
.sv-completedpage {
  padding-top: 1.5em !important;
  padding-bottom: 1.5em !important;
  background-color: unset !important;
}
.sv-title {
  color: var(--v-secondary-base) !important;
  padding-top: 0 !important;
}
.survey-container {
  width: 100%;
  min-height: 500px;
  border: none;
  .theme--light.v-application {
    background-color: unset !important;
  }
}
</style>

<script>
import * as Survey from 'survey-vue';
import 'survey-vue/modern.css';
import to from 'await-to-js';

Survey.StylesManager.applyTheme('modern');

export default {
  name: 'SurveyForm',
  data() {
    return {
      survey: null,
      model: null,
    };
  },
  computed: {
    memberId() {
      return this.$store.state.registration.memberId || this.$route.query.memberId;
    },
  },
  props: {
    customSubmit: Function,
    onSurveyCreate: {
      required: false,
      type: Function,
    },
    templateId: {
      required: true,
      type: String,
    },
    schema: {
      required: true,
      type: String,
    },
  },
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    async submit(data) {
      if (this.customSubmit) this.customSubmit(data, this.$store);
      const providerId = this.$store.getters.getProviderId() || this.$route.query.providerId;
      const { memberId, templateId } = this;
      if (!providerId || !templateId) return;

      const body = {
        completed: true,
        data: JSON.stringify(data),
        templateId,
      };

      if (!memberId) {
        this.$store.commit('setRegistration', { intake: body });
        this.$emit('newSubmission', body);
        return;
      }

      body.memberId = memberId;

      this.$emit('newSubmission', body);
    },
    async onSubmit(surveyResult, options) {
      // Setting this options disables the SurveyJS success page from showing immediately
      // eslint-disable-next-line no-param-reassign
      options.allowComplete = false;

      const [errors] = await to(this.submit(surveyResult.data));

      this.$store.commit('setNotification', {
        color: errors ? 'error' : 'success',
        text: errors || 'success',
        timeout: 3000,
      });
    },
    getModel() {
      if (!this.schema) return;

      const jsonSchema = JSON.parse(this.schema);

      this.survey = new Survey.Model(jsonSchema);

      const { lang = 'en' } = this.$route.query || {};

      this.survey.locale = lang;

      this.survey.onCompleting.add(this.onSubmit);
      this.survey.onCurrentPageChanged.add(this.scrollToTop);

      if (this.$store.state.registration.intake?.data) {
        this.survey.data = JSON.parse(this.$store.state.registration.intake.data);
        this.survey.currentPage = this.survey.pages[this.survey.pages.length - 1];
      }
      if (!this.onSurveyCreate) return;
      this.onSurveyCreate(this.$store.state, this.survey);
    },
  },
  mounted() {
    this.getModel();
  },
};
</script>
